import * as React from "react"
import {graphql} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import Divider from '../components/Divider';
import FormInquiry from '../components/FormInquiry';

// markup
const InquiryPage = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <Seo />
      <section className="text-gray-600 body-font mt-10">
        <div className="my-cont flex flex-col items-center justify-center">
          <Divider title={t('formsignup.title')} />
          <div className="text-center mb-10 w-3/4">
            <p className="leading-relaxed lg:w-3/5 mx-auto text-xl text-blue-900">{t('formsignup.subtitle')}</p>
          </div>
          <div id="inquiryform" className="w-full md:w-2/3 lg:w-1/2"></div>
          <FormInquiry />
          <p className="w-full md:w-2/3 lg:w-1/2 text-xs text-gray-500 ">{t('formsignup.sub')}</p>
        </div>
      </section>
    </Layout>
  );
};

export default InquiryPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "inquiry"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
