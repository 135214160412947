import {useEffect, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const FormInquiry = () => {
  const {i18n} = useTranslation();
  const [curlang] = useState(i18n.language);

  useEffect(() => {
    let formId = "";
    if (curlang === 'en') {
      formId = "1874482c-f3d3-4d89-9084-b05253d100dd";
    } else if (curlang === 'vi') {
      formId = "787c1bc7-aabf-49e1-a46e-b797f9dfea70";
    }
    if (window.hbspt) {
      window.hbspt.forms.create({target: "#inquiryform", region: "na1", portalId: "20091772", formId: formId});
    } else {
      let aScript = document.createElement('script');
      aScript.type = 'text/javascript';
      aScript.src = "//js.hsforms.net/forms/shell.js";
      document.head.appendChild(aScript);
      aScript.onload = () => {
        window.hbspt.forms.create({target: "#inquiryform", region: "na1", portalId: "20091772", formId: formId});
      }
    }
  }, [curlang]);

  return null;
};

export default FormInquiry;
